@font-face {
  font-family: dico-slab, serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
font-family: montserrat, sans-serif;
font-weight: 600;
font-style: normal;
}

@font-face {
  font-family: montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
font-family: ivypresto-headline, serif;
font-weight: 300;
font-style: normal;
}

$danger: #0f4757;

body {
  margin: 0;
  font-family: dico-slab, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e1e9ed;
}

.hide {
  opacity: 0.3 !important;
}

.drag-over {
  border-right: 2px solid $danger !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-logo {
  width: 160px;
  position: absolute;
  top: 0;
  left: 0;
}

a {
  text-decoration: none;
}

.main {
  min-height: calc(100vh - 64px - 100px);
}

.main {
  padding-top: 80px;
}

button.cta {
  background-color: $danger;
  color: #ffffff;
  font-size: 1.3rem;
  height: 56px;
  font-family: montserrat, sans-serif;
  &:hover {
    background-color: lighten($danger, $amount: 10%);
  }
  &.cta-small {
    font-size: 13px;
    font-weight: 500;
    padding: 4px 16px;
    height: auto;
    margin-right: 10px;
  }
}

button.cta-back {
  background-color: #ffffff;
  padding: 4px 12px !important;
  height: 40px;
  font-weight: 700;
}

button.cta-create {
  height: 52px !important;
  padding: 4px 24px !important;
  font-size: 20px;
}

.css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-left: 0 !important;
  padding-right: 24px !important;
}

.project-heading {
  text-align: right;
  position: fixed;
  top: 145px;
  width: 100%;
  max-width: 1200px;
  transform: translateX(-50%);
  left: 51%;
  padding-right: 0px;


}

textarea {
  white-space: pre-wrap !important;
}



.images-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-right: 24px;
  max-width: calc(100% - 200px);
  flex-wrap: wrap;
  margin-left: 24px;
  .image-wrap {
    position: relative;
    padding-left: 1px;
    padding-right: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-right: 3px solid rgba(0,0,0, 0.01);
    span {
      position: absolute;
      right: 0px;
      top: -10px;
    }
  }
  img {
    width: 160px;
    // pointer-events: none;
    // pointer-events: none;
  }
  label {
    margin-right: 24px;
  }
  

}

.respond-wrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  a { 
    font-weight: 700;
  }
}


.respond-wrapper.show {
  opacity: 1;
}

@media screen and (max-width: 400px) {
  .images-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-right: 24px;
    max-width: 100%;
    flex-wrap: wrap;
    margin-left: 24px;
    width: 100%;
  }
 }





